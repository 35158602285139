import React from 'react';
import { useSelector } from 'react-redux';
import { getPopups } from '../selectors';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import ShopsFeesView from '../components/ShopsFeesView';
import ErrorBoundary from '../components/ErrorBoundary';

const ShopsFeesApp = () => {
  const popups = useSelector(getPopups);

  return (
    <ErrorBoundary>
      <Header></Header>
      <MainSection popups={popups}>
        <ShopsFeesView />
      </MainSection>
      <Overlay popups={popups} />
    </ErrorBoundary>
  );
};

export default ShopsFeesApp;
